
import { Component, Prop, Vue } from "vue-property-decorator";
import { IItem } from "@/schemas/IItem";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import DraggableItem from "@/components/task/ranking/components/DraggableItem.vue";
import DroppableItem from "@/components/task/ranking/components/DroppableItem.vue";
import moveHandlers from "@/utils/moveHandlers";
import { MoveType } from "@/schemas/Enums";
import { UFliterals } from "@/utils/literals";
import { ISession } from "@/schemas/ISession";
import { IMove } from "@/schemas/IMove";

@Component({
  name: "RankingTask",
  components: {
    DraggableItem,
    DroppableItem,
    draggable,
  },
  computed: {
    ...mapState("session", ["session", "itemsToBeRanked", "rankedItems"]),
    ...mapState("user", ["userId"]),
  },
})
export default class RankingDragAndDrop extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;
  @Prop({ required: true, type: Function })
  submitRankingHandler!: any;

  session: ISession | undefined;
  userId: string | undefined;
  itemsToBeRanked: IItem[] | undefined;
  rankedItems: any | undefined;
  private isDisabled=true;
  private recentModified=false;
  private timerBuffer = [];
  dragOptions = {
    animation: 0,
    group: "ranking",
    disabled: false,
    ghostClass: "ghost",
  };

  get items(): IItem[] {
    return this.session?.activity?.items || [];
  }

  get availableItems(): IItem[] {
    return this.itemsToBeRanked || [];
  }

  async enableValidation(): Promise<void> {
    this.timerBuffer.push(1);
    await new Promise(r => setTimeout(r, 3000));
    this.timerBuffer.pop()
    if(this.timerBuffer.length < 1){
      this.recentModified = false;
      this.isSubmitDisable
    }

  }

  setAvailableItems(toSet: { toDelete: boolean; index: number }): void {
    let toDelete, index;
    ({ toDelete, index } = toSet);
    if (toDelete) {
      this.$store.commit("session/SET_ITEMS_TO_BE_RANKED", {
        remove: true,
        index,
      });
    }
  }

  get selectedItems(): IItem[] {
    return Object.values(this.rankedItems);
  }

  setSelectedItems(droppedObject: {
    item: any;
    index: any;
    old_index: any;
    from: any;
  }): void {
    let item, index, old_index, from;
    ({ item, index, old_index, from } = droppedObject);
    if (from === "selected_items") {
      this.$store.commit("session/SET_RANKED_ITEMS", {
        remove: true,
        old_index,
        add: true,
        item,
        index,
      });
    } else {
      if (!this.selectedItems[index].id) {
        this.$store.commit("session/SET_RANKED_ITEMS", {
          add: true,
          item,
          index,
        });
      }
    }
  }

  get isSubmitDisable(): boolean {
    this.isDisabled = this.selectedItems.every(
      (item: IItem) => Object.keys(item).length > 0
    );
    return (this.isDisabled && !this.recentModified)
  }

  get isSubmitBuffering():boolean{
    let bufferEmpty=false;
    if(this.timerBuffer.length<1){
      bufferEmpty=true;
    }
    else{
      bufferEmpty=false;
    }
    return (!this.isSubmitDisable && !bufferEmpty)

  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  onEndHandler(event: { item: { children: any } }): void {
    let button = [...event.item.children].find((el) =>
      el.className.includes("ranked-group-item")
    );
    if (button) {
      button.style.background = "white";
    }
  }

  onStartHandler(event: { item: { children: any } }): void {
    let button = [...event.item.children].find((el) =>
      el.className.includes("ranked-group-item")
    );
    if (button) {
      button.style.background = "#B0BEC5";
    }
  }

  onUnchooseHandler(event: { item: { children: any } }): void {
    let button = [...event.item.children].find((el) =>
      el.className.includes("ranked-group-item")
    );
    if (button) {
      button.style.background = "white";
    }
    this.enableValidation();
  }

  onChooseHandler(event: {
    item: { className: string | string[]; id: string; children: any };
  }): void {
    console.log(event.item);
    this.recentModified=true;
    if (event.item.className.includes("dropzone")) {
      // Fix for chromium and chrome
      let dropzone = document.getElementById(event.item.id);
      dropzone?.setAttribute("draggable", false);
    }
    let button = [...event.item.children].find((el) =>
      el.className.includes("ranked-group-item")
    );
    if (button) {
      button.style.background = "#CFD8DC";
    }
  }

  /**
   * Called when an item is dropped into the drop zone
   * @param index
   * @param event
   */
  onDrop(
    index: string,
    event: { dataTransfer: { getData: (arg0: string) => string } }
  ): void {
    let moveData = JSON.parse(event.dataTransfer.getData("value"));
    if (moveData.item.id) {
      let alreadyInList = this.selectedItems.findIndex(
        (item) => item.id === moveData.item.id
      );
      if (alreadyInList === -1) {
        this.setSelectedItems({
          ...moveData,
          index,
        });
        this.setAvailableItems({ toDelete: true, index: moveData.old_index });
        let addedMove = {
          user_id: this.userId,
          session_id: this.sessionId,
          dragged_from: moveData.from,
          dragged_to: "selected_items",
          item_id: moveData.item.id,
          new_index: index,
          type: MoveType.Added,
        };
        this.$store.dispatch("session/createMove", {
          move: addedMove,
        });
        let removedMove = {
          user_id: this.userId,
          session_id: this.sessionId,
          dragged_from: moveData.from,
          dragged_to: "selected_items",
          item_id: moveData.item.id,
          old_index: moveData.old_index,
          type: MoveType.Removed,
        };
        this.$store.dispatch("session/createMove", {
          move: removedMove,
        });
      } else {
        this.setAvailableItems({
          toDelete: true,
          index: moveData.old_index,
        });
      }
    }
  }

  /**
   * Called when an item is moved into the draggable list
   * @param event
   */
  onUpdate(event: {
    item: {
      id: string | undefined;
      attributes: { sourcelist: { nodeValue: any } };
    };
    oldIndex: any;
    newIndex: any;
  }): void {
    console.log(
      `${event.item.id} was moved from ${event.oldIndex} to ${event.newIndex}`
    );
    let move: IMove = {
      user_id: this.userId,
      session_id: this.sessionId,
      dragged_from: event.item.attributes.sourcelist.nodeValue,
      dragged_to: event.item.attributes.sourcelist.nodeValue,
      old_index: event.oldIndex,
      new_index: event.newIndex,
      type: MoveType.Moved,
      item_id: event.item.id,
    };
    this.$store.dispatch("session/createMove", {
      move,
      callback: () => {
        this.$store.commit("session/SET_RANKED_ITEMS", {
          whole: true,
          items: moveHandlers.moveItem(
            move,
            this.selectedItems,
            this.items.find((item) => item.id === event.item.id)
          ),
        });
      },
    });
  }
}
