
import { Vue, Component, Prop } from "vue-property-decorator";
import IndividualSessionLayout from "@/components/task/layouts/IndividualSessionLayout.vue";
import GroupSessionLayout from "@/components/task/layouts/GroupSessionLayout.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import RankingTask from "@/components/task/ranking/RankingTask.vue";
import { ActivityType, Scope } from "@/schemas/Enums";
import clientCache from "@/utils/cacheUtils";
import { clearIndividualSession } from "@/utils";

@Component({
  name: "SingleRankingTaskPage",
  components: {
    RankingTask,
    GroupSessionLayout,
    IndividualSessionLayout,
  },
  computed: {
    ...mapState("session", ["session"]),
    ...mapState("user", ["userId"]),
  },
  methods: {
    ...mapActions("session", ["submitSessionResult"]),
    ...mapMutations("session", {
      setItemsToBeRanked: "SET_ITEMS_TO_BE_RANKED",
      setRankedItems: "SET_RANKED_ITEMS",
    }),
  },
})
export default class SingleRankingTaskPage extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;

  endOfIndividualSessionHandler(): void {
    let result = {
      scope: Scope.Individual,
      type: "ranking",
      user_id: this.userId,
      session_id: this.sessionId,
      ranking_session_id: this.sessionId,
    };
    this.submitSessionResult({
      result,
      resultType: ActivityType.Ranking,
      next: () => {
        clientCache.cache(`${result.session_id}:has_submitted_single`, true);
        clearIndividualSession();
        this.$router.push({
          name: "transitionSession",
          props: {
            sessionId: this.sessionId,
          },
        });
      },
    });
  }

  setUpTask(): void {
    this.resumeMoves();
  }

  resumeMoves(): void {
    let cached_ranked_items = JSON.parse(clientCache.get("ranked_items"));
    if (cached_ranked_items && cached_ranked_items.length) {
      this.setRankedItems({
        whole: true,
        items: cached_ranked_items,
      });
    }
    let cached_available_items = JSON.parse(
      clientCache.get("items_to_be_ranked")
    );
    if (cached_available_items && cached_available_items.length) {
      this.setItemsToBeRanked({
        whole: true,
        items: cached_available_items,
      });
    }
  }
}
