
import { Vue, Component, Prop } from "vue-property-decorator";
import ProgressTimer from "@/components/task/common/ProgressTimer.vue";
import ChatRoom from "@/components/task/common/chat/ChatRoom.vue";
import { webSocketUrl } from "@/env";
import { mapState, mapGetters, mapActions } from "vuex";
import { ActivityType, Scope, SessionState } from "@/schemas/Enums";
import { IActivity } from "@/schemas/IActivity";
import LoadingMessageDialog from "@/components/dialog/LoadingMessageDialog.vue";
import DisplayEndDialog from "@/components/dialog/DisplayEndDialog.vue";

@Component({
  name: "GroupSessionLayout",
  components: {
    DisplayEndDialog,
    LoadingMessageDialog,
    ChatRoom,
    ProgressTimer,
  },
  emits: ["agreed"],
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("chatroom", ["messages"]),
    ...mapState("session", ["session"]),
    ...mapGetters("session", ["getGroupSessionTime"]),
  },
  methods: {
    ...mapActions("session", ["fetchSessionUsers"]),
  },
})
export default class GroupSession extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;

  isLoading = true;
  isFinished = false;

  remainingTime = NaN;
  beginning = null;

  waitAllConnectedTriggerId = null;
  trackTriggerId = null;

  canRequest = true;
  wait = 0;

  resumed = false;
  fullGroupFetched = false;

  chatWS: WebSocket;

  scope = "group";

  created(): void {
    this.$store.dispatch("session/fetchSession", {
      sessionId: this.sessionId,
      next: (status: SessionState) => {
        this.handleSessionStatus(status);
        this.fetchSessionUsers({ sessionId: this.sessionId });
      },
    });
  }

  beforeDestroy(): void {
    this.closeWS();
    this.clearInterval();
  }

  beforeRouteLeave(to, from, next): void {
    this.closeWS();
    this.clearInterval();
    next();
  }

  /**
   * Keep track of the activity and session
   */
  trackHandler(message: {
    status: SessionState;
    remaining_time?: number;
  }): void {
    let status = message.status;
    let remainingTime =
      message.remaining_time || message.remaining_time === 0
        ? message.remaining_time
        : NaN;
    if (status === SessionState.RunningGroup) {
      if (remainingTime > 0) {
        // set the remaining time
        this.remainingTime = remainingTime;
        // Fetch other results if not already loaded
        if (!this.fullGroupFetched) {
          this.$store.dispatch("session/fetchSession", {
            sessionId: this.sessionId,
            next: () => {
              this.fullGroupFetched = true;
            },
          });
        }
        // on group session starting clean the trigget that check if everybody is connected
        if (this.waitAllConnectedTriggerId) {
          clearInterval(this.waitAllConnectedTriggerId);
          this.waitAllConnectedTriggerId = null;
        }
        // set a new trigger of 3 seconds instead of 1
        this.trackTriggerId ??= this.allConnectedHTTPTrigger(3500);
        // resume the requires data; assignment or ranking
        if (!this.resumed) {
          let activityType = this.session.activity.type;
          console.log(
            "about to resume required things for an activity of type : ",
            activityType
          );
          if (activityType === ActivityType.Assignment) {
            this.$store.dispatch("session/getCachedAssignment", {
              sessionId: this.sessionId,
              next: () => {
                this.resumed = true;
              },
            });
          }
          if (activityType === ActivityType.Ranking) {
            this.$store.dispatch("session/getCachedRanking", this.sessionId);
            this.resumed = true;
          }
        }
        this.isLoading &&= false;
        return;
      }
      this.isLoading &&= false;
      this.isFinished = true;
      this.endOfGroupSessionHandler();
      return;
    }
    if (status === SessionState.RunningSingle) {
      this.$store.dispatch("session/disconnectFromSession", {
        sessionId: this.sessionId,
        channel: Scope.Group,
      });
      // this.$router.push({
      //   name: "singleSession",
      //   props: {
      //     sessionId: this.sessionId,
      //   },
      // });
      return;
    }
    if (status === "wait") {
      if (this.remainingTime < 0) {
        this.isLoading &&= false;
        this.isFinished = true;
        this.endOfGroupSessionHandler();
        return;
      }
    }
  }

  handleSessionStatus(status: SessionState): void {
    if (status === SessionState.Finished) {
      this.isLoading &&= false;
      this.isFinished = true;
      return;
    }
    if (status === SessionState.Created) {
      // TODO: redirect to single session
      // this.$router.push({
      //   name: "singleSession",
      //   params: {
      //     sessionId: this.sessionId,
      //   },
      // });
      this.$store.dispatch("session/updateSession", {
        sessionId: this.sessionId,
        session: { status: SessionState.RunningSingle },
      });
      this.$store.dispatch("session/connectToSession", {
        sessionId: this.sessionId,
        channel: this.scope,
      });
      this.launchSession();
      return;
    }
    if (status === SessionState.RunningSingle) {
      this.$store.dispatch("session/connectToSession", {
        sessionId: this.sessionId,
        channel: this.scope,
      });
      this.launchSession();
      return;
    }
    if (status === SessionState.RunningGroup) {
      this.$store.dispatch("session/connectToSession", {
        sessionId: this.sessionId,
        channel: this.scope,
      });
      this.launchSession();
      return;
    }
  }

  get activity(): IActivity {
    return this.session?.activity || {};
  }

  get estimatedEnd(): number {
    if (this.session.estimated_group_end_time) {
      return new Date(this.session.estimated_group_end_time)
        .getTime()
        .toString();
    } else {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.beginning ??= new Date(Date.now());
      return this.beginning
        .setMinutes(
          this.beginning.getMinutes() + this.session.setting?.group_session_time
        )
        .toString();
    }
  }

  launchSession(): void {
    this.waitAllConnectedTriggerId = this.allConnectedHTTPTrigger();
    this.chatWS = new WebSocket(
      `${webSocketUrl}/chat/${this.sessionId}/${this.userId}`
    );
    this.chatWS.onopen = function () {
      console.log("Successfully connected to the chat.");
    };
  }

  endOfGroupSessionHandler(): void {
    console.log(" End of group session handler");
  }

  cleanAndLeave(): void {
    this.closeWS();
    this.clearInterval();
    this.$store.dispatch("session/disconnectFromSession", {
      sessionId: this.sessionId,
      channel: this.scope,
    });
    const mainContainer = document.getElementById("main-container");
    if (mainContainer) mainContainer.style.backgroundImage = ``;
  }

  allConnectedHTTPTrigger(timeout: number): number {
    return window.setInterval(
      () => {
        if (this.canRequest || this.wait === 10) {
          this.wait = 0;
          this.canRequest = false;
          this.$store.dispatch("session/getSessionStatus", {
            sessionId: this.sessionId,
            next: (response) => {
              this.canRequest = true;
              this.trackHandler(response);
            },
            channel: this.scope,
          });
        } else {
          this.wait++;
        }
      },
      timeout ? timeout : 1500
    );
  }

  closeWS(): void {
    if (this.chatWS) {
      this.chatWS.close(1000);
    }
  }

  clearInterval(): void {
    if (this.waitAllConnectedTriggerId) {
      window.clearInterval(this.waitAllConnectedTriggerId);
    }
    if (this.trackTriggerId) {
      window.clearInterval(this.trackTriggerId);
    }
  }
}
